import elf from './elf.jpg'
import fruit from './fruit.jpg'
import moon from './moon.jpg'
import closet from './closet.jpg'
import tea from './tea.jpg'
import ls from './101S116.jpg'

const stable = [
  {
    text: '好好吃水果，照顧自己和最愛的他',
    product: '日日好果',
    link: 'https://designlab.re/product/%E3%80%902019-%E6%97%A5%E6%97%A5%E5%A5%BD%E6%9E%9C2-0%E6%B5%B7%E5%A0%B1%E3%80%91/?utm_source=love&utm_medium=shop',
    img: fruit,
  },
  {
    text: '有自信的體態，讓兩人感情升溫',
    product: '動動小人',
    link: 'https://designlab.re/product/workout/?utm_source=love&utm_medium=shop',
    img: elf,
  },
  {
    text: '從古今中外的愛情故事中思考，你們想要走去哪裡？',
    product: '愛情社會學',
    link: 'http://ocw.aca.ntu.edu.tw/ntu-ocw/ocw/cou/101S116',
    img: ls,
  },
]

const shy = [
  {
    text: '凡事想太多不好，跟月老說說就好',
    product: '參拜月老流程圖',
    link: 'https://designlab.re/product/%E3%80%90%E5%8F%83%E6%8B%9C%E6%9C%88%E8%80%81%E6%B5%81%E7%A8%8B%E8%B3%87%E8%A8%8A%E5%9C%96%E8%A1%A8%E6%B5%B7%E5%A0%B1%E3%80%91/?utm_source=love&utm_medium=shop',
    img: moon,
  },
  {
    text: '早茶輕發酵醒腦、下午中發酵提神、睡前重發酵避免睡不著',
    product: '暖男暖女必備圖',
    link: 'https://designlab.re/product/%E3%80%90%E8%8C%B6%E9%A1%8F%E8%A7%80%E8%89%B2%EF%BC%BF%E5%8F%B0%E7%81%A3%E4%BB%A3%E8%A1%A8%E8%8C%B6%E8%91%89%E8%B3%87%E8%A8%8A%E5%9C%96%E8%A1%A8%E6%B5%B7%E5%A0%B1%E3%80%91/?utm_source=love&utm_medium=shop',
    img: tea,
  },
  {
    text: '有根據的思考，讓兩人關係愈來愈好',
    product: '海苔熊愛情心理',
    link: 'https://www.facebook.com/Haitaibear/',
    img: 'https://scontent.ftpe7-4.fna.fbcdn.net/v/t1.0-9/381976_113798848782992_622084266_n.jpg?_nc_cat=109&_nc_ht=scontent.ftpe7-4.fna&oh=44bf39a721c15f33fa45342d7c8379b3&oe=5C9F0D63',
  },
]

const understand = [
  {
    text: '先了解自己，才能找到適合的另一半',
    product: '從衣櫃看你的戀愛傾向',
    link: 'https://simplequiz.github.io/fashion/#intro?utm_source=love&utm_medium=shop',
    img: closet,
  },
  {
    text: '以心理學作為基礎結合大數據資料庫，找不到都難',
    product: '用心理學幫你找對象',
    link: 'https://www.meettheone.com.tw/?p=12',
    img: 'https://scontent.ftpe8-3.fna.fbcdn.net/v/t1.0-9/36633850_483162558771753_8311688855170318336_o.png?_nc_cat=107&_nc_ht=scontent.ftpe8-3.fna&oh=eb0dabaeab681b919a86577b34fe666d&oe=5CD6FD10'
  },
  {
    text: '男生應該瞭解女生、女生更該瞭解自己',
    product: '保持活力才會散發魅力',
    link: 'https://www.niusnews.com/',
    img: 'https://scontent.ftpe7-2.fna.fbcdn.net/v/t1.0-9/20155939_1586274424750402_695026408419490783_n.png?_nc_cat=1&_nc_ht=scontent.ftpe7-2.fna&oh=3e999036039acd759289523a1e869dec&oe=5CD10076'
  },
]

export default [
  {
    name: '穩定交往中',
    products: stable
  },
  {
    name: '曖昧不能說',
    products: shy
  },
  {
    name: '對象還沒有',
    products: understand
  },
]
