import React from 'react';

import Page from '../Page'

import Box from '../../components/Box';
import Flex from '../../components/Flex';
import Text from '../../components/Text';
import Link from '../../components/Link';
import PageStart from '../../components/PageStart';

import ShopCircle from './ShopCircle'
import shops from './shops'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme';

const Shop = () => {
  const left = (
    <PageStart
      minWidth="75%"
      firstTitle="愛情選物店"
      descrip="找到了適合談戀愛的月份後，想要知道讓自己脫單率再提高，或是讓感情加溫的秘訣嗎？除了情話靈感產生器，我們也從三個面向著手，替大家精選了跟愛情相關的作品～"
      subDescrip="快來逛逛愛情選物店，找到最適合自己的物品或方法吧！"
    ></PageStart>
  )

  const right = (
    <Box>
      {shops.map(({ name, products }) => (
        <Box key={name} my="2em" px={mobileOrDesktop('1em', '0em')}>
          <Box borderLeft="4px solid" borderColor="primary" px="0.75em">
            <Text.h4 fontFamily="Noto Serif TC">{name}</Text.h4>
          </Box>
          <Flex flexWrap="wrap">
            {products.map(({ text, product, link, img }, j) => (
              <Box key={j} width={[1,null,null,null,null, 1/2]}>
                <Flex p="1em" alignItems="center">
                  <Box width="8em">
                    <Link display="block" style={{ borderRadius: '50%' }} href={link}>
                      <ShopCircle src={img} />
                    </Link>
                  </Box>
                  <Box flex={1} px="1em">
                    <Link href={link} fontSize="1.25em" mb="0.5em">
                      <Text>{product}</Text>
                    </Link>
                    <Text>{text}</Text>
                  </Box>
                </Flex>
              </Box>
            ))}
          </Flex>
        </Box>
      ))}
    </Box>
  )
  return (
    <Page left={left} right={right} />
  );
}

export default Shop;
