import React from 'react';
import styled from 'styled-components';
import Box from '../../components/Box';
import BackgroundImage from '../../components/BackgroundImage';

const WithHover = styled(Box)`
  .bg {
    transition: transform 0.5s ease;
  }
  &:hover {
    .bg {
      transform: scale(1.2);
    }
  }
`

const ShopCircle = ({ children, ...props }) => {
  return (
    <WithHover position="relative" borderRadius="50%" overflow="hidden">
      <BackgroundImage
        className="bg"
        borderRadius="50%"
        {...props}
      />
      <Box position="absolute" top="50%" transform="translateY(-50%)" color="white">
        {children}
      </Box>
    </WithHover>
  );
};

export default ShopCircle;
