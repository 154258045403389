import React from 'react';
import Page from '../containers/ShopPage'

const shop = () => {
  return (
    <Page />
  );
};

export default shop;
